/* components/IconList.svelte generated by Svelte v3.31.2 */
import {
	SvelteComponent,
	append,
	attr,
	destroy_each,
	detach,
	element,
	init,
	insert,
	noop,
	safe_not_equal,
	set_data,
	space,
	text
} from "svelte/internal";

import iconTub from "/assets/tub.svg";
import iconSketch from "/assets/sketch.svg";
import iconTools from "/assets/tools.svg";

function add_css() {
	var style = element("style");
	style.id = "svelte-t03vqx-style";
	style.textContent = ".root.svelte-t03vqx{display:flex;flex-direction:column}.item.svelte-t03vqx{display:flex;align-items:center;margin-bottom:var(--block-padding-v)}img.svelte-t03vqx{flex:0 0 25vw;margin-right:var(--block-padding-h)}@media screen and (min-width: 600px){.root.svelte-t03vqx{flex-direction:row;justify-content:space-around;align-items:flex-start}.item.svelte-t03vqx{align-items:center;justify-content:center;flex-direction:column;margin-bottom:0;text-align:center;margin:0 1rem;width:33.33%;max-width:30rem}img.svelte-t03vqx{margin-right:0;width:10rem;flex:0;margin-bottom:var(--block-padding-h)}}";
	append(document.head, style);
}

function get_each_context(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[2] = list[i].iconId;
	child_ctx[3] = list[i].description;
	return child_ctx;
}

// (60:2) {#each items as { iconId, description }}
function create_each_block(ctx) {
	let div;
	let img;
	let img_alt_value;
	let img_src_value;
	let t0;
	let p;
	let t1_value = /*description*/ ctx[3] + "";
	let t1;
	let t2;

	return {
		c() {
			div = element("div");
			img = element("img");
			t0 = space();
			p = element("p");
			t1 = text(t1_value);
			t2 = space();
			attr(img, "alt", img_alt_value = /*icons*/ ctx[1][/*iconId*/ ctx[2]].alt);
			if (img.src !== (img_src_value = /*icons*/ ctx[1][/*iconId*/ ctx[2]].src)) attr(img, "src", img_src_value);
			attr(img, "class", "svelte-t03vqx");
			attr(div, "class", "item svelte-t03vqx");
		},
		m(target, anchor) {
			insert(target, div, anchor);
			append(div, img);
			append(div, t0);
			append(div, p);
			append(p, t1);
			append(div, t2);
		},
		p(ctx, dirty) {
			if (dirty & /*items*/ 1 && img_alt_value !== (img_alt_value = /*icons*/ ctx[1][/*iconId*/ ctx[2]].alt)) {
				attr(img, "alt", img_alt_value);
			}

			if (dirty & /*items*/ 1 && img.src !== (img_src_value = /*icons*/ ctx[1][/*iconId*/ ctx[2]].src)) {
				attr(img, "src", img_src_value);
			}

			if (dirty & /*items*/ 1 && t1_value !== (t1_value = /*description*/ ctx[3] + "")) set_data(t1, t1_value);
		},
		d(detaching) {
			if (detaching) detach(div);
		}
	};
}

function create_fragment(ctx) {
	let div;
	let each_value = /*items*/ ctx[0];
	let each_blocks = [];

	for (let i = 0; i < each_value.length; i += 1) {
		each_blocks[i] = create_each_block(get_each_context(ctx, each_value, i));
	}

	return {
		c() {
			div = element("div");

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].c();
			}

			attr(div, "class", "root svelte-t03vqx");
		},
		m(target, anchor) {
			insert(target, div, anchor);

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].m(div, null);
			}
		},
		p(ctx, [dirty]) {
			if (dirty & /*items, icons*/ 3) {
				each_value = /*items*/ ctx[0];
				let i;

				for (i = 0; i < each_value.length; i += 1) {
					const child_ctx = get_each_context(ctx, each_value, i);

					if (each_blocks[i]) {
						each_blocks[i].p(child_ctx, dirty);
					} else {
						each_blocks[i] = create_each_block(child_ctx);
						each_blocks[i].c();
						each_blocks[i].m(div, null);
					}
				}

				for (; i < each_blocks.length; i += 1) {
					each_blocks[i].d(1);
				}

				each_blocks.length = each_value.length;
			}
		},
		i: noop,
		o: noop,
		d(detaching) {
			if (detaching) detach(div);
			destroy_each(each_blocks, detaching);
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let { items } = $$props;

	const icons = {
		tub: { src: iconTub, alt: "icon of a bathtub" },
		sketch: {
			src: iconSketch,
			alt: "icon of a pencil, ruler and paper"
		},
		tools: { src: iconTools, alt: "icon of tools" }
	};

	$$self.$$set = $$props => {
		if ("items" in $$props) $$invalidate(0, items = $$props.items);
	};

	return [items, icons];
}

class IconList extends SvelteComponent {
	constructor(options) {
		super();
		if (!document.getElementById("svelte-t03vqx-style")) add_css();
		init(this, options, instance, create_fragment, safe_not_equal, { items: 0 });
	}
}

export default IconList;