/* components/Block.svelte generated by Svelte v3.31.2 */
import {
	SvelteComponent,
	append,
	attr,
	create_slot,
	detach,
	element,
	init,
	insert,
	safe_not_equal,
	transition_in,
	transition_out,
	update_slot
} from "svelte/internal";

function add_css() {
	var style = element("style");
	style.id = "svelte-kw4p5l-style";
	style.textContent = ".root.svelte-kw4p5l{padding:var(--block-padding-v) var(--block-padding-h);background-color:var(--brand-color)}.light.svelte-kw4p5l{background-color:white;color:var(--brand-color)}p{max-width:50rem}p:last-child{margin-bottom:0}";
	append(document.head, style);
}

function create_fragment(ctx) {
	let div;
	let div_class_value;
	let current;
	const default_slot_template = /*#slots*/ ctx[2].default;
	const default_slot = create_slot(default_slot_template, ctx, /*$$scope*/ ctx[1], null);

	return {
		c() {
			div = element("div");
			if (default_slot) default_slot.c();
			attr(div, "class", div_class_value = "root " + /*style*/ ctx[0] + " svelte-kw4p5l");
		},
		m(target, anchor) {
			insert(target, div, anchor);

			if (default_slot) {
				default_slot.m(div, null);
			}

			current = true;
		},
		p(ctx, [dirty]) {
			if (default_slot) {
				if (default_slot.p && dirty & /*$$scope*/ 2) {
					update_slot(default_slot, default_slot_template, ctx, /*$$scope*/ ctx[1], dirty, null, null);
				}
			}

			if (!current || dirty & /*style*/ 1 && div_class_value !== (div_class_value = "root " + /*style*/ ctx[0] + " svelte-kw4p5l")) {
				attr(div, "class", div_class_value);
			}
		},
		i(local) {
			if (current) return;
			transition_in(default_slot, local);
			current = true;
		},
		o(local) {
			transition_out(default_slot, local);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(div);
			if (default_slot) default_slot.d(detaching);
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let { $$slots: slots = {}, $$scope } = $$props;
	let { style } = $$props;

	$$self.$$set = $$props => {
		if ("style" in $$props) $$invalidate(0, style = $$props.style);
		if ("$$scope" in $$props) $$invalidate(1, $$scope = $$props.$$scope);
	};

	return [style, $$scope, slots];
}

class Block extends SvelteComponent {
	constructor(options) {
		super();
		if (!document.getElementById("svelte-kw4p5l-style")) add_css();
		init(this, options, instance, create_fragment, safe_not_equal, { style: 0 });
	}
}

export default Block;