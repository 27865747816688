/* components/Theme.svelte generated by Svelte v3.31.2 */
import {
	SvelteComponent,
	append,
	create_slot,
	element,
	init,
	safe_not_equal,
	transition_in,
	transition_out,
	update_slot
} from "svelte/internal";

function add_css() {
	var style = element("style");
	style.id = "svelte-bqr0hp-style";
	style.textContent = ":root{--brand-color:#9b134d;--base-font-size:16px;--h2-font-size:1.5rem;--block-padding-h:1.5rem;--block-padding-v:2rem}@media screen and (min-width: 600px){:root{--base-font-size:20px;--h2-font-size:3rem;--block-padding-h:3rem;--block-padding-v:4rem}}body{font-family:museo-sans, Helvetica, sans-serif;color:white;background-color:var(--brand-color);font-size:var(--base-font-size);margin:0}html{box-sizing:border-box}*, *::before, *::after {box-sizing:inherit}p{line-height:1.5;margin-top:0}a{color:white;font-weight:bold}h1, h2, h3, h4, h5, h6{font-family:museo-slab, Arial, Helvetica, sans-serif;font-weight:100;margin-top:0}h2{font-size:var(--h2-font-size)}";
	append(document.head, style);
}

function create_fragment(ctx) {
	let current;
	const default_slot_template = /*#slots*/ ctx[1].default;
	const default_slot = create_slot(default_slot_template, ctx, /*$$scope*/ ctx[0], null);

	return {
		c() {
			if (default_slot) default_slot.c();
		},
		m(target, anchor) {
			if (default_slot) {
				default_slot.m(target, anchor);
			}

			current = true;
		},
		p(ctx, [dirty]) {
			if (default_slot) {
				if (default_slot.p && dirty & /*$$scope*/ 1) {
					update_slot(default_slot, default_slot_template, ctx, /*$$scope*/ ctx[0], dirty, null, null);
				}
			}
		},
		i(local) {
			if (current) return;
			transition_in(default_slot, local);
			current = true;
		},
		o(local) {
			transition_out(default_slot, local);
			current = false;
		},
		d(detaching) {
			if (default_slot) default_slot.d(detaching);
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let { $$slots: slots = {}, $$scope } = $$props;

	$$self.$$set = $$props => {
		if ("$$scope" in $$props) $$invalidate(0, $$scope = $$props.$$scope);
	};

	return [$$scope, slots];
}

class Theme extends SvelteComponent {
	constructor(options) {
		super();
		if (!document.getElementById("svelte-bqr0hp-style")) add_css();
		init(this, options, instance, create_fragment, safe_not_equal, {});
	}
}

export default Theme;