/* components/Carousel.svelte generated by Svelte v3.31.2 */
import {
	SvelteComponent,
	append,
	attr,
	binding_callbacks,
	destroy_each,
	detach,
	element,
	init,
	insert,
	noop,
	safe_not_equal
} from "svelte/internal";

import Flickity from "flickity";
import "flickity-imagesloaded";
import { onMount } from "svelte";

function add_css() {
	var style = element("style");
	style.id = "svelte-16mwexk-style";
	style.textContent = "img.svelte-16mwexk{display:block;height:60vh}.flickity-button-icon{fill:white}.flickity-button{background:var(--brand-color);opacity:0.8}.flickity-button:hover{background:var(--brand-color);opacity:1}";
	append(document.head, style);
}

function get_each_context(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[3] = list[i].alt;
	child_ctx[4] = list[i].src;
	return child_ctx;
}

// (43:2) {#each items as { alt, src }}
function create_each_block(ctx) {
	let img;
	let img_alt_value;
	let img_src_value;

	return {
		c() {
			img = element("img");
			attr(img, "alt", img_alt_value = /*alt*/ ctx[3]);
			if (img.src !== (img_src_value = /*src*/ ctx[4])) attr(img, "src", img_src_value);
			attr(img, "class", "svelte-16mwexk");
		},
		m(target, anchor) {
			insert(target, img, anchor);
		},
		p(ctx, dirty) {
			if (dirty & /*items*/ 1 && img_alt_value !== (img_alt_value = /*alt*/ ctx[3])) {
				attr(img, "alt", img_alt_value);
			}

			if (dirty & /*items*/ 1 && img.src !== (img_src_value = /*src*/ ctx[4])) {
				attr(img, "src", img_src_value);
			}
		},
		d(detaching) {
			if (detaching) detach(img);
		}
	};
}

function create_fragment(ctx) {
	let div;
	let each_value = /*items*/ ctx[0];
	let each_blocks = [];

	for (let i = 0; i < each_value.length; i += 1) {
		each_blocks[i] = create_each_block(get_each_context(ctx, each_value, i));
	}

	return {
		c() {
			div = element("div");

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].c();
			}

			attr(div, "class", "root");
		},
		m(target, anchor) {
			insert(target, div, anchor);

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].m(div, null);
			}

			/*div_binding*/ ctx[2](div);
		},
		p(ctx, [dirty]) {
			if (dirty & /*items*/ 1) {
				each_value = /*items*/ ctx[0];
				let i;

				for (i = 0; i < each_value.length; i += 1) {
					const child_ctx = get_each_context(ctx, each_value, i);

					if (each_blocks[i]) {
						each_blocks[i].p(child_ctx, dirty);
					} else {
						each_blocks[i] = create_each_block(child_ctx);
						each_blocks[i].c();
						each_blocks[i].m(div, null);
					}
				}

				for (; i < each_blocks.length; i += 1) {
					each_blocks[i].d(1);
				}

				each_blocks.length = each_value.length;
			}
		},
		i: noop,
		o: noop,
		d(detaching) {
			if (detaching) detach(div);
			destroy_each(each_blocks, detaching);
			/*div_binding*/ ctx[2](null);
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let { items } = $$props;
	let carouselElement;

	onMount(() => {
		new Flickity(carouselElement,
		{
				imagesLoaded: true,
				contain: true,
				pageDots: false,
				wrapAround: true
			});
	});

	function div_binding($$value) {
		binding_callbacks[$$value ? "unshift" : "push"](() => {
			carouselElement = $$value;
			$$invalidate(1, carouselElement);
		});
	}

	$$self.$$set = $$props => {
		if ("items" in $$props) $$invalidate(0, items = $$props.items);
	};

	return [items, carouselElement, div_binding];
}

class Carousel extends SvelteComponent {
	constructor(options) {
		super();
		if (!document.getElementById("svelte-16mwexk-style")) add_css();
		init(this, options, instance, create_fragment, safe_not_equal, { items: 0 });
	}
}

export default Carousel;