/* App.svelte generated by Svelte v3.31.2 */
import {
	SvelteComponent,
	create_component,
	destroy_component,
	detach,
	element,
	init,
	insert,
	mount_component,
	noop,
	safe_not_equal,
	space,
	transition_in,
	transition_out
} from "svelte/internal";

import Block from "./components/Block.svelte";
import Header from "./components/Header.svelte";
import Carousel from "./components/Carousel.svelte";
import Theme from "./components/Theme.svelte";
import IconList from "./components/IconList.svelte";
import Testimonies from "./components/Testimonies.svelte";

function create_default_slot_4(ctx) {
	let h2;
	let t1;
	let p;

	return {
		c() {
			h2 = element("h2");
			h2.textContent = "The best room in the house.";
			t1 = space();
			p = element("p");
			p.textContent = "Lorem ipsum dolor sit amet consectetur adipisicing elit. Necessitatibus minus voluptas distinctio! Delectus dignissimos nostrum quis pariatur minima. Consequuntur reprehenderit placeat recusandae quam, quibusdam ratione eveniet dolorum porro delectus harum.";
		},
		m(target, anchor) {
			insert(target, h2, anchor);
			insert(target, t1, anchor);
			insert(target, p, anchor);
		},
		d(detaching) {
			if (detaching) detach(h2);
			if (detaching) detach(t1);
			if (detaching) detach(p);
		}
	};
}

// (50:2) <Block>
function create_default_slot_3(ctx) {
	let h2;
	let t1;
	let iconlist;
	let current;

	iconlist = new IconList({
			props: { items: /*servicesItems*/ ctx[1] }
		});

	return {
		c() {
			h2 = element("h2");
			h2.textContent = "What we do";
			t1 = space();
			create_component(iconlist.$$.fragment);
		},
		m(target, anchor) {
			insert(target, h2, anchor);
			insert(target, t1, anchor);
			mount_component(iconlist, target, anchor);
			current = true;
		},
		p: noop,
		i(local) {
			if (current) return;
			transition_in(iconlist.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(iconlist.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(h2);
			if (detaching) detach(t1);
			destroy_component(iconlist, detaching);
		}
	};
}

// (54:2) <Block style='light'>
function create_default_slot_2(ctx) {
	let h2;
	let t1;
	let testimonies;
	let current;

	testimonies = new Testimonies({
			props: { items: /*testimonyItems*/ ctx[2] }
		});

	return {
		c() {
			h2 = element("h2");
			h2.textContent = "What our customers are saying";
			t1 = space();
			create_component(testimonies.$$.fragment);
		},
		m(target, anchor) {
			insert(target, h2, anchor);
			insert(target, t1, anchor);
			mount_component(testimonies, target, anchor);
			current = true;
		},
		p: noop,
		i(local) {
			if (current) return;
			transition_in(testimonies.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(testimonies.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(h2);
			if (detaching) detach(t1);
			destroy_component(testimonies, detaching);
		}
	};
}

// (58:2) <Block>
function create_default_slot_1(ctx) {
	let h2;
	let t1;
	let p;

	return {
		c() {
			h2 = element("h2");
			h2.textContent = "Get in touch";
			t1 = space();
			p = element("p");
			p.innerHTML = `Give us a call on <strong>07123 345 6789</strong> or email us at <a href="mailto:contact@candk.com">contact@candk.com</a>`;
		},
		m(target, anchor) {
			insert(target, h2, anchor);
			insert(target, t1, anchor);
			insert(target, p, anchor);
		},
		d(detaching) {
			if (detaching) detach(h2);
			if (detaching) detach(t1);
			if (detaching) detach(p);
		}
	};
}

// (43:0) <Theme>
function create_default_slot(ctx) {
	let header;
	let t0;
	let block0;
	let t1;
	let carousel;
	let t2;
	let block1;
	let t3;
	let block2;
	let t4;
	let block3;
	let current;
	header = new Header({});

	block0 = new Block({
			props: {
				$$slots: { default: [create_default_slot_4] },
				$$scope: { ctx }
			}
		});

	carousel = new Carousel({
			props: { items: /*carouselItems*/ ctx[0] }
		});

	block1 = new Block({
			props: {
				$$slots: { default: [create_default_slot_3] },
				$$scope: { ctx }
			}
		});

	block2 = new Block({
			props: {
				style: "light",
				$$slots: { default: [create_default_slot_2] },
				$$scope: { ctx }
			}
		});

	block3 = new Block({
			props: {
				$$slots: { default: [create_default_slot_1] },
				$$scope: { ctx }
			}
		});

	return {
		c() {
			create_component(header.$$.fragment);
			t0 = space();
			create_component(block0.$$.fragment);
			t1 = space();
			create_component(carousel.$$.fragment);
			t2 = space();
			create_component(block1.$$.fragment);
			t3 = space();
			create_component(block2.$$.fragment);
			t4 = space();
			create_component(block3.$$.fragment);
		},
		m(target, anchor) {
			mount_component(header, target, anchor);
			insert(target, t0, anchor);
			mount_component(block0, target, anchor);
			insert(target, t1, anchor);
			mount_component(carousel, target, anchor);
			insert(target, t2, anchor);
			mount_component(block1, target, anchor);
			insert(target, t3, anchor);
			mount_component(block2, target, anchor);
			insert(target, t4, anchor);
			mount_component(block3, target, anchor);
			current = true;
		},
		p(ctx, dirty) {
			const block0_changes = {};

			if (dirty & /*$$scope*/ 8) {
				block0_changes.$$scope = { dirty, ctx };
			}

			block0.$set(block0_changes);
			const block1_changes = {};

			if (dirty & /*$$scope*/ 8) {
				block1_changes.$$scope = { dirty, ctx };
			}

			block1.$set(block1_changes);
			const block2_changes = {};

			if (dirty & /*$$scope*/ 8) {
				block2_changes.$$scope = { dirty, ctx };
			}

			block2.$set(block2_changes);
			const block3_changes = {};

			if (dirty & /*$$scope*/ 8) {
				block3_changes.$$scope = { dirty, ctx };
			}

			block3.$set(block3_changes);
		},
		i(local) {
			if (current) return;
			transition_in(header.$$.fragment, local);
			transition_in(block0.$$.fragment, local);
			transition_in(carousel.$$.fragment, local);
			transition_in(block1.$$.fragment, local);
			transition_in(block2.$$.fragment, local);
			transition_in(block3.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(header.$$.fragment, local);
			transition_out(block0.$$.fragment, local);
			transition_out(carousel.$$.fragment, local);
			transition_out(block1.$$.fragment, local);
			transition_out(block2.$$.fragment, local);
			transition_out(block3.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(header, detaching);
			if (detaching) detach(t0);
			destroy_component(block0, detaching);
			if (detaching) detach(t1);
			destroy_component(carousel, detaching);
			if (detaching) detach(t2);
			destroy_component(block1, detaching);
			if (detaching) detach(t3);
			destroy_component(block2, detaching);
			if (detaching) detach(t4);
			destroy_component(block3, detaching);
		}
	};
}

function create_fragment(ctx) {
	let theme;
	let current;

	theme = new Theme({
			props: {
				$$slots: { default: [create_default_slot] },
				$$scope: { ctx }
			}
		});

	return {
		c() {
			create_component(theme.$$.fragment);
		},
		m(target, anchor) {
			mount_component(theme, target, anchor);
			current = true;
		},
		p(ctx, [dirty]) {
			const theme_changes = {};

			if (dirty & /*$$scope*/ 8) {
				theme_changes.$$scope = { dirty, ctx };
			}

			theme.$set(theme_changes);
		},
		i(local) {
			if (current) return;
			transition_in(theme.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(theme.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(theme, detaching);
		}
	};
}

function instance($$self) {
	let carouselItems = [
		{
			alt: "Bathroom",
			src: "https://ksassets.timeincuk.net/wp/uploads/sites/56/2020/02/jungle-bathroom-2.jpg"
		},
		{
			alt: "Bathroom",
			src: "https://static.dezeen.com/uploads/2020/01/hoxton-southwark-hotel-interiors-ennismore-london_dezeen_2364_sq.jpg"
		},
		{
			alt: "Bathroom",
			src: "https://i.pinimg.com/originals/4e/2b/c1/4e2bc1412d2f3702e3159aa94a0ca913.jpg"
		},
		{
			alt: "Bathroom",
			src: "https://i.pinimg.com/736x/8e/78/b3/8e78b381365ce268553efc47470b847f.jpg"
		},
		{
			alt: "Bathroom",
			src: "https://ksassets.timeincuk.net/wp/uploads/sites/56/2020/02/jungle-bathroom-2.jpg"
		},
		{
			alt: "Bathroom",
			src: "https://static.dezeen.com/uploads/2020/01/hoxton-southwark-hotel-interiors-ennismore-london_dezeen_2364_sq.jpg"
		},
		{
			alt: "Bathroom",
			src: "https://i.pinimg.com/originals/4e/2b/c1/4e2bc1412d2f3702e3159aa94a0ca913.jpg"
		},
		{
			alt: "Bathroom",
			src: "https://i.pinimg.com/736x/8e/78/b3/8e78b381365ce268553efc47470b847f.jpg"
		}
	];

	let servicesItems = [
		{
			iconId: "sketch",
			description: "Necessitatibus minus voluptas distinctio! Delectus dignissimos."
		},
		{
			iconId: "tools",
			description: "Nostrum quis pariatur minima. Consequuntur reprehenderit placeat recusandae quam, quibusdam ratione eveniet dolorum porro delectus harum."
		},
		{
			iconId: "tub",
			description: "Consequuntur reprehenderit placeat recusandae quam, quibusdam ratione eveniet dolorum porro delectus harum."
		}
	];

	let testimonyItems = [
		{
			name: "John Smith",
			description: "Necessitatibus minus voluptas distinctio! Delectus dignissimos."
		},
		{
			name: "Joe Blogs",
			description: "Nostrum quis pariatur minima. Consequuntur reprehenderit placeat recusandae quam, quibusdam ratione eveniet dolorum porro delectus harum."
		},
		{
			name: "Jane Doe",
			description: "Consequuntur reprehenderit placeat recusandae quam, quibusdam ratione eveniet dolorum porro delectus harum."
		}
	];

	return [carouselItems, servicesItems, testimonyItems];
}

class App extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, {});
	}
}

export default App;