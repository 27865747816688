/* components/Testimonies.svelte generated by Svelte v3.31.2 */
import {
	SvelteComponent,
	append,
	attr,
	binding_callbacks,
	destroy_each,
	detach,
	element,
	init,
	insert,
	noop,
	safe_not_equal,
	set_data,
	space,
	text
} from "svelte/internal";

import Flickity from "flickity";
import "flickity-fade";
import { onMount } from "svelte";

function add_css() {
	var style = element("style");
	style.id = "svelte-1vrfek3-style";
	style.textContent = "blockquote.svelte-1vrfek3{width:100%;height:20rem;display:flex;align-items:center;padding:0 3rem}p.svelte-1vrfek3{font-size:24px}cite.svelte-1vrfek3:before{content:'\\2014 \\0020'}";
	append(document.head, style);
}

function get_each_context(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[3] = list[i].name;
	child_ctx[4] = list[i].description;
	return child_ctx;
}

// (42:2) {#each items as { name, description }}
function create_each_block(ctx) {
	let blockquote;
	let div;
	let p;
	let t0;
	let t1_value = /*description*/ ctx[4] + "";
	let t1;
	let t2;
	let t3;
	let cite;
	let t4_value = /*name*/ ctx[3] + "";
	let t4;
	let t5;

	return {
		c() {
			blockquote = element("blockquote");
			div = element("div");
			p = element("p");
			t0 = text("\"");
			t1 = text(t1_value);
			t2 = text("\"");
			t3 = space();
			cite = element("cite");
			t4 = text(t4_value);
			t5 = space();
			attr(p, "class", "svelte-1vrfek3");
			attr(cite, "class", "svelte-1vrfek3");
			attr(blockquote, "class", "svelte-1vrfek3");
		},
		m(target, anchor) {
			insert(target, blockquote, anchor);
			append(blockquote, div);
			append(div, p);
			append(p, t0);
			append(p, t1);
			append(p, t2);
			append(div, t3);
			append(div, cite);
			append(cite, t4);
			append(blockquote, t5);
		},
		p(ctx, dirty) {
			if (dirty & /*items*/ 1 && t1_value !== (t1_value = /*description*/ ctx[4] + "")) set_data(t1, t1_value);
			if (dirty & /*items*/ 1 && t4_value !== (t4_value = /*name*/ ctx[3] + "")) set_data(t4, t4_value);
		},
		d(detaching) {
			if (detaching) detach(blockquote);
		}
	};
}

function create_fragment(ctx) {
	let div;
	let each_value = /*items*/ ctx[0];
	let each_blocks = [];

	for (let i = 0; i < each_value.length; i += 1) {
		each_blocks[i] = create_each_block(get_each_context(ctx, each_value, i));
	}

	return {
		c() {
			div = element("div");

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].c();
			}

			attr(div, "class", "root");
		},
		m(target, anchor) {
			insert(target, div, anchor);

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].m(div, null);
			}

			/*div_binding*/ ctx[2](div);
		},
		p(ctx, [dirty]) {
			if (dirty & /*items*/ 1) {
				each_value = /*items*/ ctx[0];
				let i;

				for (i = 0; i < each_value.length; i += 1) {
					const child_ctx = get_each_context(ctx, each_value, i);

					if (each_blocks[i]) {
						each_blocks[i].p(child_ctx, dirty);
					} else {
						each_blocks[i] = create_each_block(child_ctx);
						each_blocks[i].c();
						each_blocks[i].m(div, null);
					}
				}

				for (; i < each_blocks.length; i += 1) {
					each_blocks[i].d(1);
				}

				each_blocks.length = each_value.length;
			}
		},
		i: noop,
		o: noop,
		d(detaching) {
			if (detaching) detach(div);
			destroy_each(each_blocks, detaching);
			/*div_binding*/ ctx[2](null);
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let { items } = $$props;
	let carouselElement;

	onMount(() => {
		new Flickity(carouselElement,
		{
				pageDots: false,
				wrapAround: true,
				fade: true,
				autoPlay: 5000,
				pauseAutoPlayOnHover: false,
				prevNextButtons: false
			});
	});

	function div_binding($$value) {
		binding_callbacks[$$value ? "unshift" : "push"](() => {
			carouselElement = $$value;
			$$invalidate(1, carouselElement);
		});
	}

	$$self.$$set = $$props => {
		if ("items" in $$props) $$invalidate(0, items = $$props.items);
	};

	return [items, carouselElement, div_binding];
}

class Testimonies extends SvelteComponent {
	constructor(options) {
		super();
		if (!document.getElementById("svelte-1vrfek3-style")) add_css();
		init(this, options, instance, create_fragment, safe_not_equal, { items: 0 });
	}
}

export default Testimonies;