/* components/Header.svelte generated by Svelte v3.31.2 */
import {
	SvelteComponent,
	append,
	attr,
	detach,
	element,
	init,
	insert,
	noop,
	safe_not_equal,
	space
} from "svelte/internal";

import logoSrc from "/assets/logo-01.svg";

function add_css() {
	var style = element("style");
	style.id = "svelte-gfinsn-style";
	style.textContent = ".root.svelte-gfinsn{height:50vh}img.svelte-gfinsn{object-fit:cover;width:100%;height:100%}h1.svelte-gfinsn{width:300px;position:absolute;background:var(--brand-color);padding:var(--block-padding-h)}";
	append(document.head, style);
}

function create_fragment(ctx) {
	let div;
	let h1;
	let img0;
	let img0_src_value;
	let t;
	let img1;
	let img1_src_value;

	return {
		c() {
			div = element("div");
			h1 = element("h1");
			img0 = element("img");
			t = space();
			img1 = element("img");
			if (img0.src !== (img0_src_value = logoSrc)) attr(img0, "src", img0_src_value);
			attr(img0, "alt", "C & K Designs, Bathroom and Kitchen Specialists");
			attr(img0, "class", "svelte-gfinsn");
			attr(h1, "class", "svelte-gfinsn");
			if (img1.src !== (img1_src_value = bathroomSrc)) attr(img1, "src", img1_src_value);
			attr(img1, "alt", "A bathroom");
			attr(img1, "class", "svelte-gfinsn");
			attr(div, "class", "root svelte-gfinsn");
		},
		m(target, anchor) {
			insert(target, div, anchor);
			append(div, h1);
			append(h1, img0);
			append(div, t);
			append(div, img1);
		},
		p: noop,
		i: noop,
		o: noop,
		d(detaching) {
			if (detaching) detach(div);
		}
	};
}

const bathroomSrc = "https://cdn.styleblueprint.com/wp-content/uploads/2020/08/SB-Memphis-KitchenUnlimited-StyleBlueprint_Bathrooms-1-1-scaled.jpg";

class Header extends SvelteComponent {
	constructor(options) {
		super();
		if (!document.getElementById("svelte-gfinsn-style")) add_css();
		init(this, options, null, create_fragment, safe_not_equal, {});
	}
}

export default Header;